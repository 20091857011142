//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { routeTransitionFade } from '@/mixins/route-transitions'
export default {
  mixins: [routeTransitionFade],
  data() {
    return {
      menuClicked: false,
    }
  },
  computed: {
    isBurgerActive() {
      return this.$store.getters['nav/toggleSidebar']
    },
    isVisible() {
      return this.menuClicked
    },
  },
  methods: {
    toggle() {
      this.menuClicked = true
      this.$store.dispatch('nav/toggleSidebar')
    },
  },
}
