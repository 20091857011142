//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    toggleSidebar() {
      return this.$store.getters['nav/toggleSidebar']
    },
  },
}
