//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: 'COHERENCE: An Interactive Art Gallery',
    },
    description: {
      type: String,
      default:
        'A collaboration between husband and wife, developer and artist, Joe and Jean Bauer. Made with love in Portland, OR.',
    },
    image: {
      type: String,
      default:
        'https://images.prismic.io/jeanlovesjoe/aa73c761-be56-4359-b788-69b9b9198822_coherence.png?auto=compress,format',
    },
  },
  head() {
    return {
      meta: [
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: this.title,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: this.description,
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: this.image,
        },
        {
          hid: 'twitter:image:alt',
          name: 'twitter:image:alt',
          content: this.title,
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.title,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.description,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.image,
        },
        {
          hid: 'og:image:secure_url',
          property: 'og:image:secure_url',
          content: this.image,
        },
        {
          hid: 'og:image:alt',
          property: 'og:image:alt',
          content: this.title,
        },
      ],
    }
  },
  computed: {
    isHomePage() {
      if (this.$route.path === '/' || this.$route.path === '/home') {
        return true
      } else {
        return false
      }
    },
    isPrintsPage() {
      if (this.$route.path === '/prints') {
        return true
      } else {
        return false
      }
    },
  },
}
