import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  CartButton: () => import('../../components/CartButton.vue' /* webpackChunkName: "components/cart-button" */).then(c => wrapFunctional(c.default || c)),
  DesktopCreator: () => import('../../components/DesktopCreator.vue' /* webpackChunkName: "components/desktop-creator" */).then(c => wrapFunctional(c.default || c)),
  Menu: () => import('../../components/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c)),
  MenuButton: () => import('../../components/MenuButton.vue' /* webpackChunkName: "components/menu-button" */).then(c => wrapFunctional(c.default || c)),
  MobileCreator: () => import('../../components/MobileCreator.vue' /* webpackChunkName: "components/mobile-creator" */).then(c => wrapFunctional(c.default || c)),
  WobblyCircle: () => import('../../components/WobblyCircle.vue' /* webpackChunkName: "components/wobbly-circle" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
