import { render, staticRenderFns } from "./default.vue?vue&type=template&id=e2a277c8&comments=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Menu: require('/opt/build/repo/components/Menu.vue').default,MenuButton: require('/opt/build/repo/components/MenuButton.vue').default,CartButton: require('/opt/build/repo/components/CartButton.vue').default})
