//
//
//
//
//
//
//
//
//

import { routeTransitionFade } from '@/mixins/route-transitions'
export default {
  mixins: [routeTransitionFade],
}
