import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a1fcc888 = () => interopDefault(import('./prismic/pages/preview.vue' /* webpackChunkName: "" */))
const _44b764da = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _5445a534 = () => interopDefault(import('../pages/create.vue' /* webpackChunkName: "pages/create" */))
const _f48f1950 = () => interopDefault(import('../pages/gallery.vue' /* webpackChunkName: "pages/gallery" */))
const _e4da2a60 = () => interopDefault(import('../pages/prints.vue' /* webpackChunkName: "pages/prints" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/preview",
    component: _a1fcc888,
    name: "prismic-preview"
  }, {
    path: "/about",
    component: _44b764da,
    name: "about"
  }, {
    path: "/create",
    component: _5445a534,
    name: "create"
  }, {
    path: "/gallery",
    component: _f48f1950,
    name: "gallery"
  }, {
    path: "/prints",
    component: _e4da2a60,
    name: "prints"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
